
body {
  background-color: rgb(252, 227, 138);
}



.App {
  text-align: center;
}

.bold-large-text {
    color: black;           /* Set the text color to black */
    font-size: 1rem;     /* Make the font size larger */
    font-weight: none;      /* Make the text bold */
}

.large-black-email {
    color: black;           /* Set the text color to black */
    font-size: 1.2rem;      /* Make the font size larger */
    text-decoration: none;  /* Remove underline from the link */
}

.large-black-email:hover {
    text-decoration: underline; /* Underline on hover for better user experience */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
      animation: App-logo-pulse 2s infinite ease-in-out;
   }
}

.App-header {
  background-color: rgb(252, 227, 138);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15); /* Slightly increases size */
  }
  100% {
    transform: scale(1);
  }
}
